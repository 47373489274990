const app = (function(){
    let isMenuOpen = false;

    const menuButton = document.getElementById('menu-button');
    const menuButtonSVGClosed = document.querySelector('#menu-button svg.svg-menu-closed');
    const menuButtonSVGOpen = document.querySelector('#menu-button svg.svg-menu-open');
    const menuContainer = document.getElementById('menu-container');
    const menuItems = document.querySelectorAll("#menu-container .section-list-item");
    const topbar = document.getElementsByClassName('topbar')[0];
    const nextBtn = document.getElementById('next-btn');

    let scrollIndex = 0;
    let didScrollMove = false;

    menuButtonSVGOpen.setAttribute('style', 'display: none');

    let menuItemClickHandler = (ev) => {
        ev.preventDefault();
        let target = ev.target.getAttribute('href');
        document.querySelector(target).scrollIntoView({
            behavior: 'smooth'
        });
        menuContainer.classList.add('closed');
        topbar.classList.add('closed');
        menuButtonSVGOpen.setAttribute('style', 'display: none');
        menuButtonSVGClosed.setAttribute('style', '');
        isMenuOpen = false;
    };

    menuButton.addEventListener('click', () => {
        isMenuOpen = !isMenuOpen;

        if(isMenuOpen){
            menuContainer.classList.remove('closed');
            topbar.classList.remove('closed');
            menuButtonSVGOpen.setAttribute('style', '');
            menuButtonSVGClosed.setAttribute('style', 'display: none');
        }
        else {
            menuContainer.classList.add('closed');
            topbar.classList.add('closed');
            menuButtonSVGOpen.setAttribute('style', 'display: none');
            menuButtonSVGClosed.setAttribute('style', '');
        }
    });

    nextBtn.addEventListener('click', () => {
        didScrollMove = true;
        scrollIndex++;
        if(scrollIndex >= menuItems.length){
            scrollIndex = 0;
        }
        let target = menuItems[scrollIndex];
        smoothScroll(target.getAttribute('href'));
    });

    menuItems.forEach((item) => {
        item.addEventListener('click', menuItemClickHandler);
    });
    
    window.addEventListener('activate.bs.scrollspy', function (ev) {
        let i = 0;
        for(; i < menuItems.length; ++i){
            if(ev.relatedTarget === menuItems[i].getAttribute('href')) {
                scrollIndex = i;
                break;
            }
        }
        if(didScrollMove){
            scrollIndex++;
        }
        didScrollMove = false;

        if(scrollIndex >= menuItems.length - 1){
            nextBtn.classList.add('up');
        }
        else {
            nextBtn.classList.remove('up');
        }
    });

    function smoothScroll(selector){
        document.querySelector(selector).scrollIntoView({
            behavior: 'smooth'
        });
    }

    return {
        smoothScroll: smoothScroll
    }

})();